import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  getLocationByLevel,
  getLocationByParentId,
} from '@app/adapter/catalog-service';
import { EquipmentCheckModal } from '@app/components/Registration/Confirmation/EquipmentCheckModal';
import { ProgressStepper } from '@app/components/Registration/Confirmation/ProgressStepper';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import { UploadFile } from '@app/components/UploadFile/UploadFile';
import {
  cityDataAtom,
  prefectureDataAtom,
  registerState,
  selectedCityDataAtom,
  selectedPrefectureDataAtom,
  userState,
} from '@app/domain/register';
import {
  OrganizationFormData,
  RegistrationForm,
} from '@app/schemas/registration';
import { SELECTABLE_CHARTS_TYPE, CHARTS_LABEL } from '@app/utils/constants';
import { resizeImageURL } from '@app/utils/image';

interface OrganizationFormProps {
  onSubmit: (formData: OrganizationFormData) => void;
}

/**
 * blocks-5f90
 *
 * A form to let new vendors put their information.
 * After they submit the information, tanty admins will give them checkups.
 */
export function OrganizationForm({
  onSubmit,
}: OrganizationFormProps): ReactElement {
  const userInfo = useRecoilValue(userState);
  const [registerData, setRegisterData] = useRecoilState(registerState);

  const { control, watch, handleSubmit, formState, setValue, trigger } =
    useForm<OrganizationFormData>({
      defaultValues: registerData,
      mode: 'onChange',
      resolver: RegistrationForm.resolver,
    });

  const theme = useTheme();
  const { errors, isValid } = formState;

  const shouldSkipValidation = watch('shouldSkipValidation');
  const [isShowError, setIsShowError] = useState(false);
  const [isUploadAlertDialog, setIsUploadAlertDialog] = useState(false);
  const [prefectureData, setPrefectureData] =
    useRecoilState(prefectureDataAtom);
  const [cityData, setCityData] = useRecoilState(cityDataAtom);

  const [, setSelectedPrefectureData] = useRecoilState(
    selectedPrefectureDataAtom
  );
  const setSelectedCityData = useSetRecoilState(selectedCityDataAtom);

  const watchAddressLine1 = watch('addressLine1');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLocationByLevel();
        if (response.data?.value) {
          setPrefectureData(
            response.data.value.map(({ id, name }) => ({ id, name }))
          );
        }
      } catch (error) {
        console.error('Failed to fetch prefecture data:', error);
      }
    };
    void fetchData();
  }, [setPrefectureData]);

  const handlePrefectureChange = async (e?: SelectChangeEvent<string>) => {
    if (!e) {
      const response = await getLocationByLevel();
      if (response.data?.value) {
        setPrefectureData(
          response.data.value.map(({ id, name }) => ({ id, name }))
        );
      }
      return;
    }
    const selectedId = e.target.value as string;
    const selectedPrefecture = prefectureData.find(
      (pref) => pref.id === selectedId
    );
    // 都道府県の再選択時に市町村の選択を初期化
    setValue('addressLine2', '');
    setCityData([]);

    setRegisterData((prev) => ({
      ...prev,
      addressLine1: selectedPrefecture ? selectedPrefecture.id : '',
    }));
    if (selectedPrefecture) {
      setSelectedPrefectureData(selectedPrefecture);
    }
    try {
      const response = await getLocationByParentId(selectedId);
      if (response.data?.value) {
        setCityData(response.data.value.map(({ id, name }) => ({ id, name })));
      }
    } catch (error) {
      console.error('Failed to fetch city data:', error);
    }
  };

  const handleCityChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCityId = e.target.value;
    const selectedCity = cityData.find((city) => city.id === selectedCityId);
    if (!selectedCity) {
      console.warn('[OrganizationForm] handleCityChange() unexpected city id', {
        cityData,
        selectedCityId,
      });
      return;
    }
    setSelectedCityData(selectedCity);
    const selectedPrefectureName =
      prefectureData.find((pref) => pref.id === watchAddressLine1)?.name || '';
    const fullAddress = `${selectedPrefectureName}${selectedCity.name}`;

    // 連結した都道府県と市区郡をcustomField.addressByCityに格納
    setRegisterData((prev) => ({
      ...prev,
      addressLine2: selectedCity?.id as string,
      customFields: {
        ...prev.customFields,
        addressByCity: fullAddress,
      },
    }));
    setValue('customFields.addressByCity', fullAddress);
  };

  const submit = useCallback(
    (data: OrganizationFormData) => {
      setRegisterData((prev) => ({
        ...prev,
        ...data,
        addressLine2: registerData.addressLine2,
        customFields: {
          ...prev.customFields,
          ...data.customFields,
          equipments: prev.customFields.equipments,
        },
      }));
      onSubmit(data);
    },
    [onSubmit, registerData.addressLine2, setRegisterData]
  );

  //
  // カルテ
  //
  const initialCheckedCharts = Object.entries(CHARTS_LABEL)
    .filter(([, value]) =>
      registerData.customFields?.electronicCharts?.includes(value)
    )
    .map(([key]) => key);

  const [checkedCharts, setCheckedCharts] =
    useState<string[]>(initialCheckedCharts);

  const handleSelectedCharts = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const currentChartsType = event.target.value;
    const nextCharts = checked
      ? [...checkedCharts, currentChartsType]
      : checkedCharts.filter((item) => item !== currentChartsType);
    setCheckedCharts(nextCharts);
    const electronicCharts = nextCharts
      .map((item: string) => CHARTS_LABEL[item] || null)
      .filter((item) => !!item) as Array<string>;
    setRegisterData((prev) => ({
      ...prev,
      customFields: {
        ...prev.customFields,
        electronicCharts,
      },
    }));

    // NOTE:以下２行はスキーマにcustomFields.electronicChartsを追加した関係で実装。本来はrhfのControllerを使ったほうが良いが、修正範囲が大きくなるためsetValue、triggerでバリデーションを制御。
    setValue('customFields.electronicCharts', electronicCharts);
    void trigger('customFields.electronicCharts');

    setIsShowError(true);
  };

  const handleSelectedEquipments = (equipments: string[]) => {
    setRegisterData({
      ...registerData,
      customFields: {
        ...registerData.customFields,
        equipments,
      },
    });
  };

  return (
    <Box>
      <ProgressStepper activeStep={1} />
      <Typography
        variant="h6"
        fontWeight={700}
        textAlign="center"
        sx={{ mb: 5 }}
      >
        医療機関の登録
      </Typography>
      <Typography
        variant="body2"
        textAlign="left"
        gutterBottom
        component="span"
      >
        医療機関の情報をご記入ください。登録後、すぐに利用開始できます。
        <br />
        <Grid sx={{ display: 'flex' }}>
          <Typography component="span" color="error" sx={{ mb: 5 }}>
            *
          </Typography>
          は必須事項です
        </Grid>
      </Typography>

      <form
        onSubmit={handleSubmit(submit, (errors) => {
          console.log(errors);
        })}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  法人名
                </Typography>
              </FormLabel>
              <Controller
                name="description"
                control={control}
                defaultValue={registerData.description}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={registerData.description}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        description: e.target.value,
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder={'法人名を入力'}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  医療機関名
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="name"
                control={control}
                defaultValue={registerData.name}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    value={registerData.name}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        name: e.target.value,
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="医療機関名を入力"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  担当者連絡先電話番号
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue={registerData.phoneNumber}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    value={registerData.phoneNumber}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        phoneNumber: e.target.value,
                      });
                      field.onChange(e);
                    }}
                    type="tel"
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="担当者連絡先電話番号（ハイフンなし）で入力"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid container sx={{ alignItems: 'center', pt: 3, px: 3 }}>
            <Grid item xs={9}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    display={'flex'}
                    component="span"
                  >
                    住所
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography>
                </FormLabel>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <Controller
                      name="postalCode"
                      control={control}
                      defaultValue={registerData.postalCode || ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="required"
                          value={registerData.postalCode}
                          onChange={(e) => {
                            setRegisterData({
                              ...registerData,
                              postalCode: e.target.value,
                            });
                            field.onChange(e);
                          }}
                          error={error ? true : false}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="郵便番号を入力"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {errors.postalCode &&
                errors.postalCode.type !== 'invalid_string' ? (
                  <FormHelperText error>
                    正しい郵便番号を入力してください
                  </FormHelperText>
                ) : undefined}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ pt: 3, px: 4 }} alignItems="center">
            <Box sx={{ flex: '1 1 auto', pl: 2, pt: 0.5 }}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="addressLine1"
                  control={control}
                  rules={{ required: '都道府県を選択してください' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl variant="outlined" error={!!error}>
                      <Select
                        {...field}
                        required
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          void handlePrefectureChange(e);
                        }}
                        onOpen={() => {
                          void handlePrefectureChange();
                        }}
                        margin="dense"
                        displayEmpty
                        renderValue={(selectedId) => {
                          const selectedName =
                            prefectureData.find(
                              (pref) => pref.id === selectedId
                            )?.name || '';
                          if (selectedName) {
                            return selectedName;
                          }
                          return (
                            <span
                              style={{ color: theme.customPalette.lightGray }}
                            >
                              都道府県を選択
                            </span>
                          );
                        }}
                      >
                        <MenuItem value="" disabled>
                          都道府県を選択
                        </MenuItem>
                        {prefectureData.map((prefecture) => (
                          <MenuItem key={prefecture.id} value={prefecture.id}>
                            {prefecture.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: '1 1 auto', pl: 1 }}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="addressLine2"
                  control={control}
                  defaultValue={registerData.addressLine2}
                  render={({ field, fieldState: { error } }) =>
                    registerData.addressLine1 && cityData.length === 0 ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                        <Typography ml={2}>更新中</Typography>
                      </Box>
                    ) : (
                      <TextField
                        {...field}
                        className="required"
                        value={cityData.length > 0 ? field.value : ''}
                        onChange={(e) => {
                          handleCityChange(e);
                          field.onChange(e);
                        }}
                        error={error ? true : false}
                        helperText={error?.message}
                        margin="dense"
                        placeholder="市町村を選択"
                        select
                      >
                        {cityData.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid container spacing={3} sx={{ px: 3 }}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="addressLine3"
                  control={control}
                  defaultValue={registerData.addressLine3}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      className="required"
                      value={field.value}
                      onChange={(e) => {
                        setRegisterData({
                          ...registerData,
                          addressLine3: e.target.value,
                        });
                        field.onChange(e);
                      }}
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder="町名・番地を入力"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ px: 3 }}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="customFields.addressLine4"
                  control={control}
                  defaultValue={registerData.customFields.addressLine4}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        const updatedCustomFields = {
                          ...registerData.customFields,
                          addressLine4: e.target.value,
                        };
                        setRegisterData({
                          ...registerData,
                          customFields: updatedCustomFields,
                        });
                        field.onChange(e);
                      }}
                      margin="dense"
                      placeholder="建物名・部屋番号を入力"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  アクセス情報
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.access"
                control={control}
                defaultValue={registerData.customFields.access}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          access: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="アクセス情報を入力   例）JR山手線新宿駅から徒歩5分"
                    multiline
                    rows={2}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  医療機関のWebページ
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.clinicUrl"
                control={control}
                defaultValue={registerData.customFields.clinicUrl}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          clinicUrl: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="https://www.pro-med.com"
                    multiline
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <Typography
                color="textPrimary"
                variant="subtitle2"
                fontWeight={700}
                display={'flex'}
                component="span"
              >
                標榜科目
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="customFields.department"
                control={control}
                defaultValue={registerData.customFields.department}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          department: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="一般内科、循環器内科"
                    multiline
                    rows={2}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  院長名
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setValue('shouldSkipValidation', isChecked);
                      if (isChecked) {
                        const ownerName = ` ${userInfo.customFields.familyName} ${userInfo.customFields.firstName}`;
                        setValue('customFields.ownerName', ownerName);
                        setRegisterData({
                          ...registerData,
                          customFields: {
                            ...registerData.customFields,
                            ownerName,
                          },
                        });
                      }
                    }}
                  />
                }
                label="担当者ユーザーの名前と同様"
              />
              <Controller
                name="customFields.ownerName"
                control={control}
                defaultValue={registerData.customFields.ownerName}
                rules={{
                  required: !shouldSkipValidation,
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          ownerName: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={!shouldSkipValidation && error ? true : false}
                    helperText={!shouldSkipValidation && error?.message}
                    margin="dense"
                    placeholder="院長名を入力"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              fontWeight={700}
              component="span"
            >
              検査設備
            </Typography>
            <EquipmentCheckModal
              selectedItems={registerData.customFields.equipments}
              onUpdate={handleSelectedEquipments}
            />
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  component="span"
                >
                  検査設備（その他）
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.equipmentOther"
                control={control}
                defaultValue={registerData.customFields.equipmentOther}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          equipmentOther: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    margin="dense"
                    placeholder="その他の検査設備を入力"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl
              component="fieldset"
              error={
                Boolean(errors.customFields?.electronicCharts) ||
                !checkedCharts.length
              }
            >
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  カルテ
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Grid sx={{ display: 'flex' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className="required"
                      value={SELECTABLE_CHARTS_TYPE.PAPER}
                      checked={checkedCharts.includes(
                        SELECTABLE_CHARTS_TYPE.PAPER
                      )}
                      onChange={handleSelectedCharts}
                    />
                  }
                  label="紙カルテ"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className="required"
                      value={SELECTABLE_CHARTS_TYPE.ELECTRONIC}
                      checked={checkedCharts.includes(
                        SELECTABLE_CHARTS_TYPE.ELECTRONIC
                      )}
                      onChange={handleSelectedCharts}
                    />
                  }
                  label="電子カルテ"
                />
              </Grid>
              <Grid>
                {isShowError &&
                  (errors.customFields?.electronicCharts ||
                    !checkedCharts.length) && (
                    <FormHelperText>
                      {errors.customFields?.electronicCharts?.message ||
                        'カルテを選択してください'}
                    </FormHelperText>
                  )}
              </Grid>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  component="span"
                >
                  電子カルテメーカー
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.electronicChartMaker"
                control={control}
                defaultValue={registerData.customFields.electronicChartMaker}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          electronicChartMaker: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    margin="dense"
                    placeholder="電子カルテメーカーを入力"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={10} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  display={'flex'}
                  component="span"
                >
                  医療機関イメージ・写真(サイズは最大5MBまで、縦横比３：２を推奨)
                </Typography>
              </FormLabel>
              <Controller
                name="customFields.mainImages"
                control={control}
                defaultValue={registerData.customFields.mainImages}
                render={({ field, fieldState: { error } }) => (
                  <UploadFile
                    sx={{ padding: '10px 10px 10px 0' }}
                    fileUrl={field.value}
                    isError={error ? true : false}
                    helperText={error?.message}
                    setImageUrl={async (data: string) => {
                      const updatedCustomFields = {
                        ...registerData.customFields,
                      };
                      const expectedRatio = 3 / 2;
                      const img = new Image();
                      img.onload = () => {
                        if (
                          Math.abs(expectedRatio - img.width / img.height) >
                          0.01
                        ) {
                          setIsUploadAlertDialog(true);
                        }
                        URL.revokeObjectURL(img.src);
                      };
                      img.src = data;

                      const resizeUrl = await resizeImageURL(
                        data,
                        800,
                        534,
                        expectedRatio
                      );
                      updatedCustomFields.mainImages = resizeUrl;
                      setRegisterData({
                        ...registerData,
                        customFields: updatedCustomFields,
                      });
                      field.onChange(resizeUrl);
                    }}
                    addImageButtonText="画像をアップロード"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <FormControl fullWidth variant="outlined">
              <Typography
                color="textPrimary"
                variant="subtitle2"
                fontWeight={700}
                display={'flex'}
                component="span"
              >
                医療機関の一言アピール(200文字まで)
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="customFields.clinicAppealMessage"
                control={control}
                defaultValue={registerData.customFields.clinicAppealMessage}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="required"
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        customFields: {
                          ...registerData.customFields,
                          clinicAppealMessage: e.target.value,
                        },
                      });
                      field.onChange(e);
                    }}
                    error={error ? true : false}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="当院は、県内では珍しい診療科を持ち、多種多様な症例を経験できます。急性期治療病棟での入院も対応できます。コメディカルの人材も豊富でOT、CP、ST、PTと連携することができます。
                    定期非常勤のドクターも随時募集中ですので、条件に合わずともぜひお声がけください。"
                    multiline
                    rows={9}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Grid item sx={{ pb: 3 }}>
              <Typography
                component="span"
                sx={{ color: theme.customPalette.gray, fontWeight: 300 }}
              >
                送信いただく個人情報については、厳正な管理の下、プライバシーポリシーに従って利用させていただきます。
              </Typography>
            </Grid>
            <Controller
              name="userAgreement"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={error ? true : false}>
                  <FormControlLabel
                    label={
                      <Typography component="span" variant="body2">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="https://med-pro.jp/media.dr/prodoctors_termsofservice/"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography
                            component="span"
                            color="primary"
                            variant="body2"
                          >
                            利用規約
                          </Typography>
                        </Link>
                        に同意
                      </Typography>
                    }
                    control={
                      <Checkbox
                        {...field}
                        className="required"
                        checked={registerData.userAgreement}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setRegisterData({
                            ...registerData,
                            userAgreement: isChecked,
                          });
                          field.onChange(e);
                        }}
                      />
                    }
                  />
                  {error ? (
                    <FormHelperText sx={{ ml: 0 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: -2 }}>
            <Controller
              name="privacyPolicy"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={error ? true : false}>
                  <FormControlLabel
                    label={
                      <Typography component="span" variant="body2">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="https://med-pro.jp/media.dr/en_privacypolicy/"
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography
                            component="span"
                            color="primary"
                            variant="body2"
                          >
                            プライバシーポリシー
                          </Typography>
                        </Link>
                        に同意
                      </Typography>
                    }
                    control={
                      <Checkbox
                        {...field}
                        className="required"
                        checked={registerData.privacyPolicy}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setRegisterData({
                            ...registerData,
                            privacyPolicy: isChecked,
                          });
                          field.onChange(e);
                        }}
                      />
                    }
                  />
                  {error ? (
                    <FormHelperText sx={{ ml: 0 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ px: 3 }}>
            <Grid item xs={12} sx={{ pt: 6, px: 3 }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={!isValid}
              >
                送信
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ConfirmDialog
        title="画像の縦横比が異なります、求人票のデザインが崩れるため、修正してください"
        closeButtonText="OK"
        open={isUploadAlertDialog}
        onClose={() => setIsUploadAlertDialog(false)}
      />
    </Box>
  );
}
